<template>
  <b-card v-if="!guidesCompleted" :title="translations['GlobalGuides-Title']">
    <b-row>
      <b-col md="4" v-if="guides.length">
        <knowledge-base-item
          v-for="guide in guides"
          :key="guide.Message"
          :item="guide"
          @click.native="changeActiveItem(guide.Message)"
          :active="guide.Message === activeGuideTitle"
        />
      </b-col>
      <b-col md="8" v-if="activeItem">
        <div
          class="mb-3"
          v-html="translations[`Global-Guide-${activeItem.Message}-Message`]"
        />
        <b-button
          class="ml-0 btn-primary"
          v-if="activeItem.Action"
          :data-menu-id="activeItem.Action"
          >{{ translations[`Global-Guide-${activeItem.Message}-ActionTitle`] }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import KnowledgeBaseItem from "./KnowledgebaseItem.vue";
import openWindow from "../../../src/functions/window/openWindow";
import {getGlobalGuides} from "@/services/guide";

export default {
  components: {
    KnowledgeBaseItem,
  },
  data() {
    return {
      guides: [],
      loading: false,
      items: [],
      activeGuideTitle: "",
    };
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    activeItem() {
      if (this.guides.length < 1) return null;
      return this.getItemByTitle(this.activeGuideTitle);
    },
    template() {
      return this.$store.state.window?.templateName;
    },
    guidesCompleted() {
      if (this.guides.filter((guide) => guide.Completed !== true).length) {
        return false;
      }
      return true;
    },
  },
  async created() {
    await this.fetchGuides();
    this.activeGuideTitle = this.guides[0].Message;
  },
  methods: {
    async fetchGuides() {
      this.loading = true;
      const result = await getGlobalGuides({
        subject: "",
        criteria: null,
      });
      this.guides = result.sort((a, b) => {
        a.Completed - b.Completed;
      });
      this.loading = false;
    },
    getItemByTitle(title) {
      return this.guides.find((guide) => guide.Message === title);
    },
    changeActiveItem(title) {
      this.activeGuideTitle = title;
    },
    triggerAction(subject) {
      openWindow({subject, actionName: "New"});
    },
  },
};
</script>

<style>
.startup .card-title {
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 10px;
  padding-bottom: 7px;
}
</style>
