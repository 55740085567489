import store from "../../state/store";
import axios from "axios";

export async function newWindow({subject, actionName}) {
  const data = {
    selectionInfo: {
      ActionName: actionName,
      Arguments: {},
      Data: {
        Changes: [],
        Selection: [],
      },

      Request: {
        Data: {
          Search: [],
          Filters: {},
          SortOn: null,
          SortReverse: false,
          SelectedTabActionName: null,
          PageNumber: 1,
          PageSize: 100,
          ShowCurrencyToggle: false,
          ToggleCurrency: false,
          ClientCurrency: null,
          OldClientCurrency: null,
          ForeignCurrency: null,
        },
        Criteria: [
          {
            "WarehouseID 0=": ["VERHUUR01 - Terneuzen"],
          },
        ],
        IsSubWindow: false,
        Subject: subject,
        TableToSelect: "Rental.vw_WarehouseItem",
        ParentSubject: "",
        LinkedSubject: null,
        Prefix: "Multi",
        RunDesktopHooks: true,
        RequestType: 3,
      },
    },
  };

  const result = await axios.post(
    "/Admin/WebServices/CoreWebServices.asmx/OpenNew",
    data,
    {
      params: {
        warehouse: store.state.activeWarehouse,
      },
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );
  return result;
}
