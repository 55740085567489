<template>
  <div :class="classes">
    <span v-if="item.Completed === true" class="text-success"
      ><i class="fas fa-check-circle"></i
    ></span>
    <span v-else-if="item.Type === 'Warning'" class="text-warning"
      ><i class="fas fa-info-circle"></i
    ></span>
    <span v-else-if="item.Type === 'Danger'" class="text-danger"
      ><i class="fas fa-exclamation-circle"></i
    ></span>
    {{ translations[`Global-Guide-${item.Message}-Title`] }}
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
    active: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    translations() {
      return this.$store.state.translations;
    },
    classes() {
      if (this.active) return `knowledgebase-item active ${this.item.status}`;
      return `knowledgebase-item ${this.item.status}`;
    },
  },
};
</script>

<style scoped>
.knowledgebase-item {
  padding: 20px 20px;
  background-color: #eee;
  color: #565656;
  font-weight: 700;
  cursor: pointer;
}
.knowledgebase-item:hover {
  color: #f26522;
  transition: 0.2s;
}
.knowledgebase-item.active {
  border-left: 3px solid #f26522;
  transition: 0.1s;

  color: #f26522;
  background-color: white;
}
.knowledgebase-item.success {
  text-decoration: line-through;
}
</style>
