<template>
  <b-container fluid class="startup">
    <b-row>
      <b-col md="12" lg="3">
        <startup-quick-links />
      </b-col>
      <b-col md="12" lg="6">
        <knowledgebase class="knowledgebase" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Knowledgebase from "../elements/knowledgebase/Knowledgebase.vue";
import StartupQuickLinks from "./startup/StartupQuickLinks";
export default {
  components: {
    Knowledgebase,
    StartupQuickLinks,
  },
};
</script>

<style scoped lang="scss"></style>
